import Chart from "components/Chart/Chart";
import ChartControl, { useChartControlContext } from "components/Chart/ChartControl";
import LineChart from "components/Chart/ChartTypes/LineChart";
import BarChart from "components/Chart/ChartTypes/BarChart";

export interface ChartComponentProps {
  name: string;
}

export interface ChartComponents {
  name: string;
  comp: (props: ChartComponentProps) => JSX.Element;
  props: {
    [key: string]: any;
    header: string;
  };
}

export interface ToggleChildProps {
  name: string;
  cases?: boolean;
  recovered?: boolean;
  deaths?: boolean;
}

export interface ToggleProps {
  children: (
    handleOnChangeToggle: (
      componentName: string,
      cases?: boolean,
      recovered?: boolean,
      deaths?: boolean
    ) => void,
    state: ToggleChildProps
  ) => JSX.Element;
}

export { ChartControl, useChartControlContext, LineChart, BarChart };
export default Chart;
